import { UrlHelper } from '@/common/helper/url-helper-service';
import { HttpHelper } from '@/common/helper/http-helper-service';
import { constants } from '@/common/constant/constants';
export default class commonService {
    static getDropdownAutocomplete(url, query) {
        var url = UrlHelper.getUrl(url);
        url += "?query=" + query;
        return HttpHelper.getData(url, []);
    }
}
commonService.getNewToken = (refreshToken) => {
    return HttpHelper.getData(constants.refreshToken + "?oldRefreshToken=" + refreshToken, {});
};
commonService.validateIfUserLoggedIn = () => {
    return HttpHelper.postData(constants.validateIfUserLoggedIn, {});
};
commonService.GetVersion = () => {
    return HttpHelper.getData(window.checkVersionUrl, {});
};
