import { __decorate } from "tslib";
import commonService from '@/common/services/common-service';
import _ from 'lodash';
import { Component, Vue } from 'vue-property-decorator';
let Dropdown = class Dropdown extends Vue
    .extend({
    name: "Dropdown",
    data() {
        return {
            searchAutoComplete: null,
            dataSource: this.object.source,
            selectedValue: this.object.defaultSelectedValue,
            //loading: false,
            autoCompleteMinLength: 3,
            isSettingSelectedValue: false,
            IsStateEnabled: false,
            dataObject: this.object,
        };
    },
    props: [
        'object'
    ],
    computed: {
        //selectedValue() {
        //    return (this as any).object.defaultSelectedValue;
        //}
        disabledControl() {
            try {
                var a = this.object.isDisabled();
                return a;
            }
            catch (ex) {
                console.log(ex);
            }
            return false;
        },
        _selectedValue: {
            get() {
                try {
                    var a = this.object.StateGetter();
                    return a;
                }
                catch (ex) {
                    console.log(ex);
                }
                return {};
                //return ticketEntity.query<any>().withAllRecursive().find("08d6be67-a796-2841-2818-7811b3310000").ticketStatusEntity;
            },
            set(value) {
                if (this.isSettingSelectedValue) {
                    // if parent component is explicitly setting the selected value then do nothing
                    return;
                }
                // if multiple is true then clear the typed value so it wont become chip
                this.clearSearchedText();
                if (this.dataObject.objectName != "technicianObject" && this.dataObject.objectName != "technicianTimeZoneObject") {
                    if (typeof value === "string") {
                        // if type of value is string then it means user hasn't selected any item from dropdown
                        // then clear the value
                        value = null;
                    }
                }
                if (this.dataObject.isMultiple) {
                    // let say we have printer option available in dropdown and user types prinTER 
                    // then we need to select printer option instead of creating new item prinTER
                    //value = this.cleanMultipleSelectionArray(value);
                    this.setSelectedValue(value, false);
                }
                //update selected value in parent
                this.dataObject.onSelectedValueChangedCallback(value, this.dataObject.objectName, this.dataObject.listVariableName);
            }
        }
    },
    methods: {
        getSource() {
            if (this.dataObject.sourceUrl || (this.dataObject.isAutoComplete == true && this.dataObject.sourceUrl == '')) {
                return this.dataSource;
            }
            if (this.dataObject.onQuerySearch || (this.dataObject.isAutoComplete == true && this.dataObject.onQuerySearch)) {
                return this.dataSource;
            }
            return this.dataObject.source;
        },
        getData(query) {
            if (this.dataObject.sourceUrl == '') {
                this.dataObject.showLoading = true;
                this.dataSource = _.filter(this.dataObject.source, (each) => {
                    return (each[this.dataObject.textField] && each[this.dataObject.textField].toLowerCase().indexOf(query) != -1);
                });
                this.dataObject.showLoading = false;
            }
            else if (this.dataObject.onQuerySearch) {
                this.dataObject.showLoading = true;
                this.dataObject.onQuerySearch(query).then((result) => {
                    this.dataSource = result;
                    this.dataObject.showLoading = false;
                });
            }
            else if (this.dataObject.sourceUrl) {
                this.dataObject.showLoading = true;
                commonService.getDropdownAutocomplete(this.dataObject.sourceUrl, query)
                    .then((result) => {
                    this.dataSource = result;
                    this.dataObject.showLoading = false;
                });
            }
        },
        cleanMultipleSelectionArray(array) {
            let newArray = new Array();
            //fetch existing values
            let existingItems = _.filter(array, (each) => {
                return each[this.dataObject.valueField];
            });
            //fetch new items
            let newItems = _.filter(array, (each) => {
                return !each[this.dataObject.valueField];
            });
            // remove empty item
            _.remove(newItems, (item) => {
                return this.transformStringToCompare(item) == '';
            });
            if (newItems.length > 0) {
                // remove duplicate unique items
                newItems = _.uniqBy(newItems, (each) => {
                    return this.transformStringToCompare(each);
                });
                //check if new items are available in data source then add in existing item
                newItems.forEach((each) => {
                    let source = this.dataSource.length > 0 ? this.dataSource : this.dataObject.source;
                    let temp = _.filter(source, (item) => {
                        return this.transformStringToCompare(each) == this.transformStringToCompare(item[this.dataObject.textField]);
                    });
                    if (temp.length > 0) {
                        temp[0]['index'] = each['index'];
                        existingItems.push(temp[0]);
                    }
                });
                //remove new items from newItems array that we added in existing items above
                existingItems.forEach((each) => {
                    _.remove(newItems, (item) => {
                        return this.transformStringToCompare(item) == this.transformStringToCompare(each[this.dataObject.textField]);
                    });
                });
            }
            // remove any duplicate existing items
            existingItems = _.uniqBy(existingItems, (each) => {
                return each[this.dataObject.valueField];
            });
            // insert existing items in array
            newArray.push(...existingItems);
            // insert new items in array
            newItems.forEach((each) => {
                newArray.push(this.transformString(each));
            });
            return newArray;
        },
        transformStringToCompare(value) {
            return this.transformString(value).toLowerCase();
        },
        transformString(value) {
            return value.toString().replace(/\s+/g, ' ').trim();
        },
        setSelectedValue(selectedValue, updateInParent) {
            this.isSettingSelectedValue = !updateInParent;
            this.selectedValue = selectedValue;
            if (!updateInParent)
                setTimeout(() => { this.isSettingSelectedValue = false; }, 50);
        },
        clearSearchedText() {
            if (this.dataObject.isMultiple && this.searchAutoComplete) {
                // if multiple is true then clear the typed value so it wont become chip
                this.searchAutoComplete = null;
            }
        },
        onBlurCallback() {
            this.clearSearchedText();
            this.dataObject.onBlurCallback != null && this.dataObject.onBlurCallback();
        }
    },
    mounted() {
    },
    created() {
        this.IsStateEnabled = this.dataObject.IsStateApplied();
        if (this.dataObject.setInstanceToParent == true) {
            this.dataObject.setInstanceToParentCallback(this);
        }
        if (this.dataObject.isSelect) {
            // if this is select control then clear autocomplete and source url
            this.dataObject.isAutoComplete = false;
            this.dataObject.sourceUrl = null;
            return;
        }
        if (this.dataObject.isAutoComplete) {
            // if source url is '' then it means this is client side autocomplete so clear the dataSource
            // we will fill dataSource upon typing in dataData()
            if (this.dataObject.sourceUrl == '') {
                this.dataSource = [];
            }
            return;
        }
        if (this.dataObject.onQuerySearch) {
            this.getData("");
            return;
        }
        if (this.dataObject.sourceUrl) {
            this.getData("");
            return;
        }
    },
    watch: {
        'object': {
            handler(value) {
                if (this.dataObject.isPropWatcherEnable) {
                    this.dataObject = Object.assign({}, value);
                    this.selectedValue = value.selectedValue;
                }
            },
            deep: true
        },
        //_selectedValue(value: any) {
        //    if (this.isSettingSelectedValue) {
        //        // if parent component is explicitly setting the selected value then do nothing
        //        return;
        //    }
        //    if (this.dataObject.objectName != "technicianObject" && this.dataObject.objectName != "technicianTimeZoneObject") {
        //        if (typeof value === "string") {
        //            // if type of value is string then it means user hasn't selected any item from dropdown
        //            // then clear the value
        //            value = null;
        //        }
        //    }
        //    if (this.dataObject.isMultiple) {
        //        // let say we have printer option available in dropdown and user types prinTER 
        //        // then we need to select printer option instead of creating new item prinTER
        //        value = this.cleanMultipleSelectionArray(value);
        //        this.setSelectedValue(value, false);
        //    }
        //    //update selected value in parent
        //    this.dataObject.onSelectedValueChangedCallback(value, this.dataObject.objectName, this.dataObject.listVariableName);
        //},
        selectedValue(value) {
            if (this.isSettingSelectedValue) {
                // if parent component is explicitly setting the selected value then do nothing
                return;
            }
            // if multiple is true then clear the typed value so it wont become chip
            this.clearSearchedText();
            if (this.dataObject.objectName != "technicianObject" && this.dataObject.objectName != "technicianTimeZoneObject") {
                if (typeof value === "string") {
                    // if type of value is string then it means user hasn't selected any item from dropdown
                    // then clear the value
                    value = null;
                }
            }
            if (this.dataObject.isMultiple) {
                // let say we have printer option available in dropdown and user types prinTER 
                // then we need to select printer option instead of creating new item prinTER
                value = this.cleanMultipleSelectionArray(value);
                this.setSelectedValue(value, false);
            }
            //update selected value in parent
            this.dataObject.onSelectedValueChangedCallback(value, this.dataObject.objectName, this.dataObject.listVariableName);
        },
        searchAutoComplete(query) {
            if (!this.dataObject.isAutoComplete) {
                return;
            }
            if (!query || query.length < this.autoCompleteMinLength) {
                if (!this.dataObject.isMultiple) {
                    // if auto complete is true with mutiple not true then clear the selected value
                    this.selectedValue = null;
                }
                this.dataSource = [];
                return;
            }
            this.getData(query.toLowerCase());
        }
    }
}) {
};
Dropdown = __decorate([
    Component({
        name: 'Dropdown',
    })
], Dropdown);
export default Dropdown;
